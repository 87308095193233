import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { OrderBookingParcelModel, OrderFacade, PAYMENT_TYPES } from '@app/store/order';
import { BOOKING_TYPES } from '@app/store/planning/enums';
import { BehaviorSubject, combineLatest, filter, map, Observable, take, tap } from 'rxjs';
import { FormService } from '../../services/form-edit.service';
import { SidebarBaseComponent } from '@app/shell/sidebar/components/sidebar/sidebar.component';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { Confirmable } from '@app/shared/decorators/confirmable.decorator';
import { FormArray } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import { HighlightService } from '../../../../services/highlight.service';
import { AutosuggestClosestFacade, AutosuggestFacade } from '@app/store/heremaps';
import { PopupService } from '@app/shared/popup';
import { TranslateService } from '@ngx-translate/core';
import { SelectOptionsFacade } from '@app/store/select-options';
import { PrerequisitiesWarningPopupComponent } from '@app/services/shared/components/prerequisities-warning-popup/prerequisities-warning-popup.component';

@Component({
    selector: 'app-view-parcel',
    templateUrl: './view-parcel.component.html',
    styleUrls: ['./view-parcel.component.scss', '../../../../../shell/sidebar/styles/sidebar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewParcelComponent extends SidebarBaseComponent implements OnInit, OnDestroy {
    private readonly orderFacade = inject(OrderFacade);
    private readonly formService = inject(FormService);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly highlightService = inject(HighlightService);
    protected readonly autosuggestFacade = inject(AutosuggestFacade);
    protected readonly autosuggestClosestFacade = inject(AutosuggestClosestFacade);
    private readonly popupService = inject(PopupService);
    private readonly translateService = inject(TranslateService);
    private readonly selectOptionsFacade = inject(SelectOptionsFacade);
    private previousTransactionsMethods: string[] = [];
    autosuggestLoading$ = combineLatest([this.autosuggestFacade.autosuggestLoading$, this.autosuggestClosestFacade.autosuggestLoading$]).pipe(
        map((loaders) => loaders.includes(true)),
    );
    data?: { messages: string[]; confirmKey: string; cancelKey: string };

    orderId = this.activatedRoute.snapshot.firstChild?.paramMap.get('order') || null;
    bookingId = this.activatedRoute.snapshot.firstChild?.paramMap.get('parcel') || null;
    order$ = this.orderFacade.order$;
    booking$!: Observable<OrderBookingParcelModel>;
    bookings$!: Observable<OrderBookingParcelModel[]>;
    saved$ = this.orderFacade.saved$;
    partnersSelect$ = this.selectOptionsFacade.partners$;
    skipConfirm = false;
    isInvoiceMethod$ = new BehaviorSubject<boolean>(false);

    form = this.formService.form;

    get transactionArr() {
        return <FormArray>this.form.controls.transactions;
    }

    totalPrice$ = this.order$.pipe(
        map((order) => {
            const booking = order.bookings.find((booking) => booking.id === this.bookingId);
            if (booking) {
                return booking.items.reduce((a, b) => a + b.price, 0);
            }
            return 0;
        }),
    );

    bookingTypes = BOOKING_TYPES;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this.orderId !== null && this.bookingId !== null) {
            this.orderFacade.getOrder(this.orderId);

            this.subscriptions$.add(
                this.order$
                    .pipe(
                        take(1),
                        tap((order) => this.formService.setValue(order, <string>this.bookingId)),
                    )
                    .subscribe(),
            );

            this.booking$ = this.order$.pipe(
                map((order) => order.bookings.find((booking) => booking.id === this.bookingId)),
                filter((booking): booking is OrderBookingParcelModel => booking !== undefined),
            );

            this.bookings$ = this.order$.pipe(
                map((order) => <OrderBookingParcelModel[]>order.bookings.filter((booking) => 'parcel' in booking && booking.id !== this.bookingId)),
            );
        }

        this.subscriptions$.add(this.formService.form.controls.parcels.valueChanges.pipe(tap(() => this.changeDetectorRef.detectChanges())).subscribe());

        this.subscriptions$.add(
            this.saved$
                .pipe(
                    filter((saved) => saved !== null && saved !== false),
                    tap(() => {
                        this.skipConfirm = true;
                        this.goBack();
                    }),
                )
                .subscribe(),
        );
        this.subscriptions$.add(
            this.formService.form.controls.transactions.valueChanges
                .pipe(
                    map((transactions) => {
                        this.logPaymentMethodChanges();
                        if (
                            transactions.some(
                                (transaction) =>
                                    transaction.paymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT || transaction.paymentMethod === PAYMENT_TYPES.COMPANY_INVOICE,
                            )
                        ) {
                            transactions.some((transaction) => {
                                transaction.paymentMethod === PAYMENT_TYPES.COMPANY_INVOICE
                                    ? this.isInvoiceMethod$.next(true)
                                    : this.isInvoiceMethod$.next(false);
                                this.form.controls.invoiceDetails.patchValue({
                                    isCompany: true,
                                    invoice: true,
                                });

                                if (transaction.paymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT) {
                                    this.form.controls.invoiceDetails.controls.isCompany.enable();
                                    this.form.controls.invoiceDetails.controls.invoice.enable();
                                } else {
                                    this.form.controls.invoiceDetails.controls.isCompany.disable();
                                    this.form.controls.invoiceDetails.controls.invoice.disable();
                                }
                            });
                            this.form.controls.details.patchValue({
                                partner: this.form.controls.details.controls.partner.getRawValue(),
                            });
                            this.form.controls.invoiceDetails.patchValue({
                                isCompany: this.isInvoiceMethod$.value,
                                invoice: true,
                            });

                            this.form.controls.invoiceDetails.controls.isCompany.setValidators(FormValidators.RequiredTrue);
                            this.form.controls.invoiceDetails.controls.invoice.setValidators(FormValidators.RequiredTrue);
                            this.form.controls.invoiceDetails.controls.nip.addValidators([FormValidators.Required]);
                        } else {
                            this.isInvoiceMethod$.next(false);
                            this.form.controls.invoiceDetails.controls.isCompany.clearValidators();
                            this.form.controls.invoiceDetails.controls.invoice.clearValidators();
                            this.form.controls.invoiceDetails.controls.nip.removeValidators([FormValidators.Required]);
                            this.form.controls.invoiceDetails.controls.isCompany.enable();
                            this.form.controls.invoiceDetails.controls.invoice.enable();
                        }
                        this.form.controls.invoiceDetails.controls.isCompany.updateValueAndValidity();
                        this.form.controls.invoiceDetails.controls.invoice.updateValueAndValidity();
                        this.form.controls.invoiceDetails.controls.nip.updateValueAndValidity();
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.formService.form.controls.invoiceDetails.controls.invoice.valueChanges
                .pipe(
                    map((invoice) => {
                        if (invoice === false) {
                            this.formService.form.controls.invoiceDetails.patchValue({ isCompany: false });
                            this.form.controls.invoiceDetails.controls.nip.disable();
                            this.form.controls.invoiceDetails.controls.companyName.disable();
                        } else {
                            this.form.controls.invoiceDetails.controls.nip.enable();
                            this.form.controls.invoiceDetails.controls.companyName.enable();
                        }
                    }),
                )
                .subscribe(),
        );

        if (this.orderId !== null && this.bookingId !== null) {
            this.highlightService.setHighlight(this.orderId, this.bookingId);
        }
        this.subscriptions$.add(
            this.isInvoiceMethod$
                .pipe(
                    tap((isInvoice) => {
                        if (isInvoice) {
                            this.form.controls.invoiceDetails.patchValue({
                                isCompany: isInvoice,
                                invoice: true,
                                nip: this.form.controls.invoiceDetails.controls.nip.value,
                                companyName: '',
                                address: {
                                    city: '',
                                    country: '',
                                    houseNumber: '',
                                    postalCode: '',
                                    street: '',
                                },
                            });
                        }
                    }),
                )
                .subscribe(),
        );
        this.subscriptions$.add(
            this.popupService.closingSubject
                .pipe(
                    filter((reason) => reason === 'confirmed'),
                    tap(() => this.addOrder()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.orderFacade.checkPrerequisities$
                .pipe(
                    tap((prerequisities) => {
                        if (prerequisities.hasDuplicate || prerequisities.isPast) {
                            this.data = {
                                messages: [],
                                cancelKey: 'button.no',
                                confirmKey: 'button.yes',
                            };
                            if (prerequisities.hasDuplicate) {
                                this.data.messages.push(this.translateService.instant('MODAL.duplicate_warning'));
                            }
                            if (prerequisities.isPast) {
                                this.data.messages.push(this.translateService.instant('MODAL.is_past_warning'));
                            }
                            this.popupService.open(PrerequisitiesWarningPopupComponent, this.data);
                        } else {
                            this.addOrder();
                        }
                    }),
                )
                .subscribe(),
        );
    }
    private logPaymentMethodChanges(): void {
        this.form.getRawValue().transactions.forEach((transaction, index) => {
            const previousTransaction = this.previousTransactionsMethods[index];

            if (previousTransaction) {
                const previousPaymentMethod = previousTransaction;
                const currentPaymentMethod = transaction.paymentMethod;

                if (
                    (previousPaymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT || previousPaymentMethod === PAYMENT_TYPES.COMPANY_INVOICE) &&
                    currentPaymentMethod !== previousPaymentMethod &&
                    currentPaymentMethod !== PAYMENT_TYPES.COMPANY_INVOICE &&
                    currentPaymentMethod !== PAYMENT_TYPES.GROUP_SETTLEMENT
                ) {
                    this.form.controls.invoiceDetails.patchValue({
                        isCompany: false,
                        invoice: false,
                    });
                }
            }
        });

        const paymentMethods = this.form
            .getRawValue()
            .transactions.flatMap((transaction) => transaction.paymentMethod || [])
            .filter((method) => method !== null && method !== undefined);

        this.previousTransactionsMethods = paymentMethods;
    }
    get touched() {
        return this.form.touched;
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.orderId && this.form.valid) {
            const value = this.form.getRawValue();
            this.orderFacade.checkPrerequisitiesUpdate(value, this.orderId);
        }
    }

    addOrder() {
        this.form.markAllAsTouched();
        if (this.orderId && this.form.valid) {
            const value = this.form.getRawValue();
            this.orderFacade.updateOrder(this.orderId, value);
        }
    }

    onCancelClick(event: MouseEvent) {
        event.preventDefault();
        this.goBack();
    }

    trackByBooking(index: number, booking: OrderBookingParcelModel) {
        return booking.id;
    }

    override ngOnDestroy(): void {
        this.formService.cleanup();
        this.orderFacade.clear();
        super.ngOnDestroy();
    }

    @Confirmable({ messageKey: 'MODAL.exit_without_save', confirmKey: 'MODAL.yes', cancelKey: 'MODAL.no' })
    close() {
        this.skipConfirm = true;
        this.goBack();
    }

    private goBack() {
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const params = { ...tree.root.children['primary'].segments[0].parameters };
        delete params['order'];
        delete params['parcel'];
        tree.root.children['primary'].segments[0].parameters = params;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }
    openHistory(event: MouseEvent) {
        event.preventDefault();
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const parameters = { ...tree.root.children['primary'].segments[0].parameters };
        parameters['history'] = this.orderId!;
        tree.root.children['primary'].segments[0].parameters = parameters;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }
}
