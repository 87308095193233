<div class="box border rounded p-12 bg-white position-relative">
    <div class="bar bar-{{ form.getRawValue().status }}"></div>

    <div class="d-flex mb-6">
        <h4>{{ transactionIndex === 0 ? 'Koszyk Główny' : 'Koszyk dodatkowy ' + transactionIndex }}</h4>
        <span
            *ngIf="form.getRawValue().id === null"
            class="pointer ms-auto"
            appIcon="x-lg"
            color="danger"
            size="7"
            (click)="onRemoveTransactionClick($event, transactionIndex)"
            (keypress)="onRemoveTransactionClick($event, transactionIndex)"
            tabindex="1"
        ></span>
    </div>
    <div class="mb-12">
        <div *ngIf="form.getRawValue().status !== null" class="d-flex align-items-center mb-3">
            <span [appIcon]="form.getRawValue().status | paymentStatusIcon" color="default" size="6" class="me-4"></span>
            <span>{{ 'payment.payment-status.' + form.getRawValue().status + '.label' | translate }}</span>
            <a
                *ngIf="(form.getRawValue().status === 'pending' || form.getRawValue().status === 'ok') && form.getRawValue().closed !== true"
                tabindex="1"
                class="fs-14 text-default d-flex align-items-center link ms-auto"
            >
                <!-- <span appIcon="send" color="default" size="6" class="me-4"></span> -->
                <!-- <span>Wyślij link do płatności</span> -->
            </a>
        </div>
        <div>
            <span
                >{{
                    (payment$ | async)?.fiscalizedAt !== null
                        ? ('payment.fiscalized-status.completed' | translate)
                        : ('payment.fiscalized-status.pending' | translate)
                }}
                {{ (payment$ | async)?.fiscalizedAt }}</span
            >
        </div>
    </div>

    <form [formGroup]="form">
        <div formArrayName="items">
            <div *ngFor="let item of form.controls.items.controls; index as itemIndex; let last = last" [class.mb-4]="!last">
                <app-transaction-edit-set-control
                    [transactionIndex]="transactionIndex"
                    [closed]="form.controls.closed.getRawValue()"
                    [formGroupName]="itemIndex"
                    (deleteItemEvent)="onDeleteItemEvent($event)"
                    (createItemEvent)="onCreateItemEvent($event)"
                ></app-transaction-edit-set-control>
            </div>
        </div>

        <div *ngIf="form.getRawValue().closed === false" class="pt-6 text-start">
            <a href="#" class="link text-primary fs-16" (click)="addItemClick($event)">Dodaj Usługę</a>
        </div>

        <div class="d-flex align-items-center justify-content-start mt-5">
            <div class="payment-method">
                <app-form-dropdown
                    formControlGroup="paymentMethod"
                    [label]="'PAYMENTS.FORM.SERVICES_PAYMENTS.payment_method' | translate"
                    [options]="paymentTypesItems$ | async"
                    [block]="true"
                    [showClear]="true"
                    [showErrors]="false"
                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                ></app-form-dropdown>
            </div>
            <div class="currency mx-3">
                <app-form-dropdown
                    formControlGroup="currency"
                    [label]="'PAYMENTS.FORM.SERVICES_PAYMENTS.currency' | translate"
                    [options]="currencyTypes"
                    [block]="true"
                    [showClear]="true"
                    [showErrors]="false"
                    [placeholder]="'FORMS.PLACEHOLDERS.selectValue' | translate"
                ></app-form-dropdown>
            </div>

            <div class="pt-10">
                <p-checkbox [readonly]="true" (click)="confirmPaid()" formControlName="paid" label="Zamówienie opłacone" [binary]="true"></p-checkbox>
            </div>

            <!-- <div class="ms-8 pt-8" *ngIf="form.getRawValue().status === 'pending' || form.getRawValue().status === 'ok'">
                        <a (click)="recordPaymentClick($event, 'completed')" class="d-inline-flex align-items-center link fs-14 tw-text-primary" href="#">
                            <span appIcon="credit-card" color="secondary" size="6" class="me-4"></span>
                            <span>Odnotuj płatność</span>
                        </a>
                    </div> -->
            <div class="ms-auto pt-10 text-end">
                <div>
                    <span class="fw-700 me-2 fs-20 text-uppercase">{{ 'PAYMENTS.FORM.SERVICES_PAYMENTS.sum' | translate }}</span>
                    <span class="fw-700 fs-20 text-uppercase">{{ (priceTotal$ | async) || 0 }} {{ form.getRawValue().currency }}</span>
                </div>
                <ng-container *ngIf="payment$ | async as payment">
                    <span class="fs-14 fw-700"
                        >Opłacono:
                        {{
                            payment && (payment.status === 'paid' || payment.status === 'completed')
                                ? payment.amount + ' ' + payment.currency
                                : '0 ' + payment.currency
                        }}</span
                    >
                </ng-container>
            </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-start mt-5">
                <ng-container *ngIf="(payment$ | async) === null">
                    <div class="mt-5 d-flex justify-content-end"><p-button (click)="createPayment()">Stwórz Płatność </p-button></div></ng-container
                >{{ (transaction$ | async)?.paymentId }}
                {{ (payment$ | async)?.status === 'ok' }}
                <ng-container
                    *ngIf="
                        ((transaction$ | async)?.paymentId && (payment$ | async)?.status === 'pending') ||
                        ((transaction$ | async)?.paymentId && (payment$ | async)?.status === 'ok') ||
                        (payment$ | async)?.status === 'ok'
                    "
                >
                    <p-button (click)="updateTransactionMethod()">Zaktualizuj metodę płatności</p-button>
                    <p-button class="mx-3" (click)="updateTransactionCurrency()">Zaktualizuj walutę</p-button>
                </ng-container>
            </div> -->
    </form>
</div>
